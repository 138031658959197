import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { graphql, Link } from "gatsby";
import 'react-tabs/style/react-tabs.css';
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from "swiper";

import { Animated } from "react-animated-css";
import PopupForm from "../components/popupForm";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";


import ReactGA from 'react-ga'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

import ReactHtmlParser from 'react-html-parser';


Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);
var accItem;
class Admissions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 90000000,
            selectedProgram: "",
			      selectedUniversity: "",
            selectedProgramlabel: "",
			      selectedDate: "",
            progressionCounty: "",
            progressionUniversity: "",
            progressionDate: "",

            
		};
	}

	handlePopupWindow = () => {
		this.setState({ time: Math.random() });
	};

   
    
	componentDidMount() {
    
        const programSelect = document.querySelector('.programSelect');
        const universitySelect = document.querySelector('.universitySelect');
        const dateSelect = document.querySelector('.dateSelect');

        let removedUniversity = [];
        let removedDate = [];

        const progressionCountrySelect = document.querySelector('.progressionCountrySelect');
        const progressionUniversitySelect = document.querySelector('.progressionUniversitySelect');
        const progressionDateSelect = document.querySelector('.progressionDateSelect');

        let removedProgressionUniversity = [];
        let removedProgressionDate = [];

        const programUniversityHolder = document.querySelector('.programUniversity-holder');
        const programDateHolder = document.querySelector('.programDate-holder');
        
        const selectProgramTitle = document.querySelector('#product-sort-box');
        const selectUniTitle = document.querySelector('#key-date-step-2');
        const selectIntakTitle = document.querySelector('#key-date-step-3');
        const noticeByIntakeDate = document.querySelector('#key-date-step-4');

        const progressionCountryTitle = document.querySelector('#product-sort-box-progression');
        const progressionUniTitle = document.querySelector('#key-date-step-2-progression');
        const progressionTitle = document.querySelector('#key-date-step-3-progression');
        const noticeByProgressioDate = document.querySelector('#key-date-step-4-progression');

        document.getElementById('speak-to-an-expert').onclick = function(){
			document.getElementById('book-an-appointment').click(); 

   

		}
         
   

    programSelect.style.fontStyle = "italic";
    dateSelect.style.fontStyle = "italic";
    universitySelect.style.fontStyle = "italic";
    progressionCountrySelect.style.fontStyle = "italic";
    progressionUniversitySelect.style.fontStyle = "italic";
    progressionDateSelect.style.fontStyle = "italic";

    // add an event listener to the program select element
programSelect.addEventListener('change', function() {
  // get the selected program values

  selectUniTitle.classList.remove('done');
  selectIntakTitle.classList.remove('done');

  selectUniTitle.classList.remove('next');
  selectIntakTitle.classList.remove('next');


  selectProgramTitle.classList.add('done');
  selectUniTitle.classList.add('next');

  programSelect.style.fontStyle = "normal";
  noticeByIntakeDate.innerHTML = "";
  noticeByIntakeDate.style.display = 'none';

  universitySelect.disabled = true;
  universitySelect.selectedIndex = 0;
  universitySelect.style.fontStyle = "italic";

  dateSelect.disabled = true;
  dateSelect.selectedIndex = 0;
  dateSelect.style.fontStyle = "italic";

  const programValues = programSelect.value.split(',');

  universitySelect.disabled = false;


removedUniversity.forEach(option => {
  universitySelect.appendChild(option);
});
removedUniversity = [];
  // loop through all options in the university select element
  for (let i = 1; i < universitySelect.options.length; i++) {
    const option = universitySelect.options[i];
    // check if the option's value matches any of the selected program values
    if (!programValues.includes(option.value)) {
  
      removedUniversity.push(option);
      universitySelect.remove(i);
      i--;
    }
  }
});

universitySelect.addEventListener('change', function() {
  // get the selected program values


  selectIntakTitle.classList.remove('done');
  selectIntakTitle.classList.remove('next');


  selectUniTitle.classList.add('done');
  selectIntakTitle.classList.add('next');

  universitySelect.style.fontStyle = "normal";
  noticeByIntakeDate.innerHTML = "";
  noticeByIntakeDate.style.display = 'none';



  const universityText = universitySelect.options[universitySelect.selectedIndex].text;
  const degreeType =
  document.querySelector('.programSelect').selectedOptions[0].textContent.includes(' Graduate')
    ? 'AAGP'
    : document.querySelector('.programSelect').selectedOptions[0].textContent.includes('Undergraduate')
    ? 'AAUG'
    : document.querySelector('.programSelect').selectedOptions[0].textContent.includes('Pre-Masters')
    ? 'UnismarterPMP'
    : document.querySelector('.programSelect').selectedOptions[0].textContent.includes('Global Year 1')
    ? 'GLOBALYEAR1'
    : document.querySelector('.programSelect').selectedOptions[0].textContent.includes('UniSmarter Program')
    ? 'Unismarter'
    : document.querySelector('.programSelect').selectedOptions[0].textContent.includes('Global Online')
    ? 'GOAP'
    : '';

//console.log('degreeType->'+degreeType);
  dateSelect.selectedIndex = 0;
  dateSelect.disabled = false;
  dateSelect.style.fontStyle = "italic";

  removedDate.forEach(option => {
    dateSelect.appendChild(option); 
  });
  removedDate = [];

  // loop through all options in the university select element
  for (let i = 1; i < dateSelect.options.length; i++) {
    const option = dateSelect.options[i];
    //console.log("data-program->"+option.getAttribute('data-program')+"-->"+ universityText);
    if (option.getAttribute('data-program') === universityText && option.getAttribute('data-degree') === degreeType) {
      
    } else {
      removedDate.push(option);
      dateSelect.remove(i);
      i--;
    }
  }
});

dateSelect.addEventListener('change', function(e) {
  // get the selected program values
  selectIntakTitle.classList.add('done');

  dateSelect.style.fontStyle = "normal";
  noticeByIntakeDate.innerHTML = dateSelect.options[dateSelect.selectedIndex].getAttribute('data-notice');
  noticeByIntakeDate.style.display = 'block';
});

selectUniTitle.addEventListener('click', function(e) {

if(universitySelect.disabled) {
    alert("Please Select Program First!");
  };
  
});

selectIntakTitle.addEventListener('click', function(e) {

  if(universitySelect.disabled) {
    alert("Please Select Program First!");
  } else  if(dateSelect.disabled) {
    alert("Please Select University!");
  }
  
});


progressionCountrySelect.addEventListener('change', function() {
  // get the selected program values

  progressionUniTitle.classList.remove('done');
  progressionTitle.classList.remove('done');

  progressionUniTitle.classList.remove('next');
  progressionTitle.classList.remove('next');


  progressionCountryTitle.classList.add('done');
  progressionUniTitle.classList.add('next');

  progressionCountryTitle.style.fontStyle = "normal";
  noticeByProgressioDate.innerHTML = "";
  noticeByProgressioDate.style.display = 'none';

  progressionUniversitySelect.disabled = true;
  progressionUniversitySelect.selectedIndex = 0;
  progressionUniversitySelect.style.fontStyle = "italic";

  progressionDateSelect.disabled = true;
  progressionDateSelect.selectedIndex = 0;
  progressionDateSelect.style.fontStyle = "italic";

  const progressionCountryValues = progressionCountrySelect.value.split(',');


  removedProgressionUniversity.forEach(option => {
    progressionUniversitySelect.appendChild(option);
  });
  removedProgressionUniversity = [];

  progressionUniversitySelect.disabled = false;
  // loop through all options in the university select element
  for (let i = 1; i < progressionUniversitySelect.options.length; i++) {
    const option = progressionUniversitySelect.options[i];
    // check if the option's value matches any of the selected program values
    if (!progressionCountryValues.includes(option.dataset.country)) {
 
      removedProgressionUniversity.push(option);
      progressionUniversitySelect.remove(i);
        i--;
    }
  }
});

progressionUniversitySelect.addEventListener('change', function() {
  // get the selected program values


  progressionTitle.classList.remove('done');
  progressionTitle.classList.remove('next');


  progressionUniTitle.classList.add('done');
  progressionTitle.classList.add('next');

  progressionUniversitySelect.style.fontStyle = "normal";
  noticeByProgressioDate.innerHTML = "";
  noticeByProgressioDate.style.display = 'none';



  const universityText = progressionUniversitySelect.options[progressionUniversitySelect.selectedIndex].text;


  progressionDateSelect.selectedIndex = 0;
  progressionDateSelect.disabled = false;
  progressionDateSelect.style.fontStyle = "italic";

  removedProgressionDate.forEach(option => {
    progressionDateSelect.appendChild(option);
  });
  removedProgressionDate = [];


  // loop through all options in the university select element


  for (let i = 1; i < progressionDateSelect.options.length; i++) {
    const option = progressionDateSelect.options[i];
    //console.log("data-program->"+option.getAttribute('data-program')+"-->"+ universityText);
    if (option.getAttribute('data-university') !== universityText ) {
      removedProgressionDate.push(option);
      progressionDateSelect.remove(i);
        i--;
    }
  }
});

progressionDateSelect.addEventListener('change', function(e) {
  // get the selected program values
  progressionTitle.classList.add('done');

  progressionUniversitySelect.style.fontStyle = "normal";
  noticeByProgressioDate.innerHTML = progressionDateSelect.options[progressionDateSelect.selectedIndex].getAttribute('data-notice');
  noticeByProgressioDate.style.display = 'block';
});

selectUniTitle.addEventListener('click', function(e) {

if(universitySelect.disabled) {
    alert("Please Select Program First!");
  };
  
});

selectIntakTitle.addEventListener('click', function(e) {

  if(universitySelect.disabled) {
    alert("Please Select Program First!");
  } else  if(dateSelect.disabled) {
    alert("Please Select University!");
  }
  
});

          accItem = document.getElementsByClassName("accordionItem");
		let accHD = document.getElementsByClassName("accordionItemHeading");
		for (let i = 0; i < accHD.length; i++) {
			accHD[i].addEventListener("click", this.toggleItem, false);
		}
	}


	accorfn = () => {
		accItem = document.getElementsByClassName("accordionItem");
		let accHD = document.getElementsByClassName("accordionItemHeading");
		for (let i = 0; i < accHD.length; i++) {
			accHD[i].addEventListener("click", this.toggleItem, false);
		}
	};
	toggleItem() {
		var itemClass = this.parentNode.className;
		for (let i = 0; i < accItem.length; i++) {
			accItem[i].className = "accordionItem close";
		}
		if (itemClass == "accordionItem close") {
			this.parentNode.className = "accordionItem open";
		}
	}
	render() {

        const allPages = this.props.data.allWordpressPage.nodes;

let post;

allPages.forEach(node => {
  if (node.wordpress_id === 7013) {
    post = node;
    //console.log("Universities->"+`The post has title "${post.title}"`);
  } else {
    //console.log("Universities->"+JSON.stringify(node.acf.select_universities)+'No post found');
  }
});


const { data } = this.props;


const intakeDates  = [];

for (let i = 0; i < this.props.data.allWordpressWpUniversities.edges.length; i++) {
  
  const node = this.props.data.allWordpressWpUniversities.edges[i].node;

  //console.log('myNode->'+JSON.stringify(node));
  if (node.acf.intake_dates_university) {
    for (let j = 0; j < node.acf.intake_dates_university.length; j++) {
     
      const university_intake_dates = node.acf.intake_dates_university[j];
      //console.log('myNode->'+JSON.stringify(university_intake_dates));

      intakeDates.push({
        wordpress_id: node.wordpress_id,
        title: node.title,
        inkate_date: university_intake_dates.intake_date_university,
        inkate_notice: university_intake_dates.intake_detail_university,
      });
    }
  }
}



       const hybridPages = allPages.filter(node => node !== post);


//console.log("hybridPages->"+JSON.stringify(hybridPages));






       const uni = allPages.filter(node => node !== post);

        const metatitle = post.acf.meta_title ;
        const metadescription = post.acf.meta_description; 
const focus_keyphrase = post.acf.focus_keyphrase; 
const canonical_url = `https://theworldgrad.com${this.props.path}`; 
var headerBackground = "/static/americanbanner-31161936b50af35a68b688be75f818f4.png";
if(post.featured_media){
	headerBackground = post.featured_media.source_url;
}

const universities =  this.props.data.allWordpressWpUniversities.edges; 




const containsPipe = (title) => {
  return title.includes("|");
};

const applicationsWithPipe = [];
const applicationsWithoutPipe = [];

post.acf.application_process.forEach((application) => {
  if (containsPipe(application.title)) {
    applicationsWithPipe.push(application);
  } else {
    applicationsWithoutPipe.push(application);
  }
});

//console.log("Universities->"+JSON.stringify(universities));

//console.log("Universities->"+JSON.stringify(hybridPages));

		if (post.featured_media) {
			headerBackground = post.featured_media.source_url;
		}
		return (
			<div className="about-page">
				<PopupForm time={this.state.time} />
				<Layout>
					<Helmet>
				 <title>{metatitle}</title>
         		 <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          
		 </Helmet>
 {/*canonical_url*/}
		 <div class="newStyle font-poppins">
     <div className="container">
		 <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
											<li class="breadcrumb-item">
												<a href="/">Home </a>
											</li>
											<li class="breadcrumb-item active"> {post.title}</li>
										</ol>
				</div>
         <section class="wow animate__fadeIn bg-light-gray padding-120px-tb sm-padding-100px-tb position-relative bg-cover480br extra-big-section" style={{ backgroundImage: "url(" + headerBackground + ")" }} >
							<div class="opacity-extra-medium bg-medium-slate-blue"></div>
							<div class="container">
								<div class="row">
									<div class="col-12 col-xl-10 col-lg-10 col-md-10 position-relative page-title-large ">
										<h1 class="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30  margin-10px-bottom">{(() => {
											if (post.acf.custom_h1 != '' && post.acf.custom_h1 != null) {
												return (post.acf.custom_h1)
											} else {
												return (post.title)

											}
										})()}
										</h1>
										<div class="alt-font text-white no-margin-bottom" dangerouslySetInnerHTML={{ __html: post.acf.banner_detail }} />
									</div>
								</div>
							</div>
						</section>


					<section  class="half-section wow animate__fadeIn">
		   <div class="container">
				<div class="row">
                    <div class="col-md-12  text-center margin-two-bottom">
                        <h2 class="margin-15px-bottom">{post.acf.application_process_title}</h2>
                    </div>
					
                    <div class="col-md-12  margin-two-bottom ">
						<div class="admission-tab-holder show-on-desktop">
						<Tabs>
							<TabList class="tabset"> 
                                {applicationsWithoutPipe.map((item, index) => (
                                <Tab 
                                    onClick={() => {
                                        ReactGA.event({
                                            category: "Institutions",
                                            action: "Clicks",
                                            label: "step" + (index+1)
                                        })
                                    }}
                                >{item.title}
                                    </Tab>
                                ))}
                            </TabList>
                            {applicationsWithoutPipe.map((itemMain, index) => (
  <TabPanel key={index}>

    {!applicationsWithPipe.some(item => item.title.startsWith(itemMain.title + ' | ')) ? (
      <div dangerouslySetInnerHTML={{ __html: itemMain.detail }} />
    ) : (
      <div class="grayfaq list-style-05">
        <div dangerouslySetInnerHTML={{ __html: itemMain.detail }} />
        {applicationsWithPipe.map((item, innerIndex) => (
          item.title.startsWith(itemMain.title + ' | ') && (
            <Accordion
              key={innerIndex}
              defaultExpanded={false}
              className="customize-accord panel bg-white box-shadow-small border-radius-5px margin-30px-bottom"
            >
              <AccordionSummary
                expandIcon={<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>}
                className="customize-accord-common customize-accord-title-title"
              >
                {item.title.substring(item.title.indexOf('|') + 1)}
              </AccordionSummary>
              <AccordionDetails className="customize-accord-body-body">
                <div dangerouslySetInnerHTML={{ __html: item.detail }} />
              </AccordionDetails>
            </Accordion>
          )
        ))}
      </div>
    )}
  </TabPanel>
))}


							</Tabs>
						</div>
                        <div className="adminssion-tab-holder grayfaq show-on-mobile">

                        <Animated animationIn="fadeInUp" animationOut="fadeInDown" isVisible={true}>

                        {applicationsWithoutPipe.map((item, index) => (
                            <Accordion defaultExpanded={false} className="customize-accord panel bg-white box-shadow-small border-radius-5px margin-30px-bottom">
                            <AccordionSummary expandIcon={<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>} className="customize-accord-common customize-accord-title-title">
                            {item.title}
                            </AccordionSummary>
                            <AccordionDetails className="customize-accord-body-body" >
                            {!item.title.includes("Complete Documentation") ? (
  <div dangerouslySetInnerHTML={{ __html: item.detail }} />
) : (
  <div>

{applicationsWithPipe.map((item, index) => (
  <div>
                 
                            <h5>{item.title.substring(item.title.indexOf("|") + 1)}</h5>
                   
                            <div  dangerouslySetInnerHTML={{ __html: item.detail }} />
                            <br/>      
							</div>   
                            ))}

  </div>
)}
                            </AccordionDetails>
                        </Accordion>
							
                            ))}
        



</Animated>
                        </div>
                    </div>
                </div>
            </div>

           
        </section>

        <section class="half-section scholarship-block bg-fast-yellow ">
							<div class="container">
								<div class="row ">
									<div class="col-12  text-center  ">
					

												<div class="no-margin-bottom sm-margin-20px-top" dangerouslySetInnerHTML={{ __html: post.acf.scholarships_content }} />

              
                <p class="margin-10px-bottom margin-10px-bottom">{post.acf.scholarships_text_before_number+' '}<br class="d-show-991" />
                <CountUp start={1} end={post.acf.scholarships_number} redraw={true} separator="," duration={1.5} formatter={(value) => value.toLocaleString()} delay={1.25}>
											{({ countUpRef, start }) => (
												<VisibilitySensor onChange={start} delayedCall>
													<span ref={countUpRef}></span>
												</VisibilitySensor>
											)}
										</CountUp> 
                    <span> {' '+post.acf.scholarships_text_after_number}</span></p>
                
												<a data-animation="animated zoomInUp" class="btn btn-theme effect btn-theme-rev btn-theme-rev-orng btn-md   margin-10px-tb"  href={post.acf.scholarships_button_link}>{post.acf.scholarships_button}</a>
                        <p class="small">{post.acf.scholarships_small_text}</p>
                        
											</div>



								


								</div>
							</div>
						</section>

		<section  class="half-section  wow animate__fadeIn admission-process-section ">
		   <div class="container">
				<div class="row">
                    <div class="col-md-12  text-center margin-two-bottom">
                        <h2 class="margin-15px-bottom">{post.acf.key_dates_title}</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-12 position-relative margin-30px-bottom">
                        <div id="product-sort-box" class="w-100">
              
                            <select className="programSelect bg-white" >
                                <option value="" class="programvalues">Select Program</option>
                                {hybridPages.map((item, index) => (
                                <option value={item.acf.select_universities_dropdown} data-postnm={item.acf.select_universities_dropdown} class="programvalues">{item.title}</option>
                                ))}
                            </select>
                                    
                           <div class="clearfix"></div>
                      </div>
                    </div>
                    <div id="key-date-step-2" class="col-12 col-lg-12 position-relative margin-30px-bottom">
                    
                        <select className="universitySelect bg-white" disabled>
                                <option value="" class="universityvalues">Select University</option>
                                {universities.map((university) => (
                                <option value={university.node.wordpress_id} data-postnm={university.node.wordpress_id} class="universityvalues">{ReactHtmlParser(university.node.title)}</option>
                                ))} 
                            </select>

                        <div class="programUniversity-holder">
                        {universities.map((university) => (
  <a
    href="#"
    data-unid={university.node.wordpress_id}
    class={`uni-pill prog-${university.node.wordpress_id}`}
  >
    {university.node.title}
  </a>
))}

                            
                            
                        </div>
                    </div>
                    <div id="key-date-step-3" class="col-12 col-lg-12 position-relative margin-30px-bottom">

  <select className="dateSelect bg-white" disabled>
                                <option value="" class="datevalues">Select Intake</option>
                                {intakeDates.map((item, index) => {
    let degree = "";
    let displayedDate = item.inkate_date;

    if (item.inkate_date.includes("(AAUG)")) {
        degree = "AAUG";
        displayedDate = displayedDate.replace("(AAUG)", "").trim();
    }

    if (item.inkate_date.includes("(AAGP)")) {
        degree = "AAGP";
        displayedDate = displayedDate.replace("(AAGP)", "").trim();
    }

    if (item.inkate_date.includes("(UnismarterPMP)")) {
      degree = "UnismarterPMP";
      displayedDate = displayedDate.replace("(UnismarterPMP)", "").trim();
  }

  if (item.inkate_date.includes("(GLOBALYEAR1)")) {
      degree = "GLOBALYEAR1";
      displayedDate = displayedDate.replace("(GLOBALYEAR1)", "").trim();
  }

  if (item.inkate_date.includes("(Unismarter)")) {
    degree = "Unismarter";
    displayedDate = displayedDate.replace("(Unismarter)", "").trim();
}

if (item.inkate_date.includes("(GOAP)")) {
  degree = "GOAP";
  displayedDate = displayedDate.replace("(GOAP)", "").trim();
}

    return (
        <option
            value={item.inkate_notice}
            data-program={ReactHtmlParser(item.title)}
            data-notice={item.inkate_notice}
            data-degree={degree}
            className="datevalues"
        >
            {displayedDate}
        </option>
    );
})}

                            </select>

  <div class="programDate-holder">
    {intakeDates.map((item, index) => (
      <a
        key={item.wordpress_id}
        href="#"
        data-value={item.inkate_date}
        data-title={item.title}
        data-notice={item.inkate_notice}
        className={`uni-pill prog${index+1} uni${item.wordpress_id}`}
      >
        {item.inkate_date}
      </a>
    ))}
  </div>
</div>
                    <div id="key-date-step-4" class="col-12 col-lg-12 position-relative margin-30px-bottom">
                   </div>

                    <div class="col-12 col-lg-12 sdsd position-relative">
                    {(() => {
											if (post.acf.cta_url === '#popup') {
												return(<a data-animation="animated zoomInUp"  class="btn btn-theme effect btn-md center-button-110 modal-popup" id="speak-to-an-expert" href="#get-in-touch">{post.acf.cta_title}</a>)
												
											} else {
												return(<a data-animation="animated zoomInUp"  class="btn btn-theme effect btn-md center-button-110 " href={post.acf.cta_url}>{post.acf.cta_title}</a>)
											}
										})()}
                    </div>
                </div>
            </div>

           
        </section>

        <section  class="half-section  wow animate__fadeIn admission-process-section bg-light-gray">
		   <div class="container">
				<div class="row">
                    <div class="col-md-12  text-center margin-two-bottom">
                        <h2 class="margin-15px-bottom">Progression Dates</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-12 position-relative margin-30px-bottom">
                        <div id="product-sort-box-progression" class="w-100">
              
                        <select className="progressionCountrySelect bg-white">
    <option value="" className="progressionCountryValues">Select Country</option>
    {this.props.data.allWordpressWpUniversities.edges
        .filter(({ node }) => node.acf.intake_dates_university_progression) // Filter universities with intake_dates_university_progression
        .reduce((uniqueCountries, { node }) => {
            if (!uniqueCountries.includes(node.acf.country)) {
                uniqueCountries.push(node.acf.country);
            }
            return uniqueCountries;
        }, [])
        .map((country, index) => (
            <option key={index} value={country} data-postnm={country} className="progressionCountryValues">{country}</option>
        ))
    }
</select>
                                    
                           <div class="clearfix"></div>
                      </div>
                    </div>
                    <div id="key-date-step-2-progression" class="col-12 col-lg-12 position-relative margin-30px-bottom">
                    
                    <select className="progressionUniversitySelect bg-white" disabled>
    <option value="" className="progressionUniversityValues">Select University</option>
    {this.props.data.allWordpressWpUniversities.edges
        .filter(({ node }) => node.acf.intake_dates_university_progression) // Filter universities with intake_dates_university_progression
        .map(({ node }) => (
            <option key={node.wordpress_id} value={node.wordpress_id} data-postnm={node.wordpress_id}  data-country={node.acf.country} className="progressionUniversityValues">
                {ReactHtmlParser(node.title)}
            </option>
        ))
    }
</select>

                        <div class="programUniversity-holder">
                        {universities.map((university) => (
  <a
    href="#"
    data-unid={university.node.wordpress_id}
    class={`uni-pill prog-${university.node.wordpress_id}`}
  >
    {university.node.title}
  </a>
))}

                            
                            
                        </div>
                    </div>
                    <div id="key-date-step-3-progression" class="col-12 col-lg-12 position-relative margin-30px-bottom">

                    <select className="progressionDateSelect bg-white" disabled>
    <option value="" className="progressionDateValues">Select Overseas Progression Date</option>
    {this.props.data.allWordpressWpUniversities.edges.map(({ node }) => (
        node.acf.intake_dates_university_progression && // Check if intake_dates_university_progression exists
        node.acf.intake_dates_university_progression.map((intakeDate, index) => (
            <option
                key={index}
                value={intakeDate.intake_date_university}
                data-university={ReactHtmlParser(node.title)}
                data-notice={intakeDate.intake_detail_university}
                className="progressionDateValues"
            >
                {intakeDate.intake_date_university}
            </option>
        ))
    ))}
</select>

  <div class="programDate-holder">
    {intakeDates.map((item, index) => (
      <a
        key={item.wordpress_id}
        href="#"
        data-value={item.inkate_date}
        data-title={item.title}
        data-notice={item.inkate_notice}
        className={`uni-pill prog${index+1} uni${item.wordpress_id}`}
      >
        {item.inkate_date}
      </a>
    ))}
  </div>
</div>
                    <div id="key-date-step-4-progression" class="col-12 col-lg-12 position-relative margin-30px-bottom">
                   </div>

                    <div class="col-12 col-lg-12 sdsd position-relative">
                    {(() => {
											if (post.acf.cta_url === '#popup') {
												return(<a data-animation="animated zoomInUp"  class="btn btn-theme effect btn-md center-button-110 modal-popup" id="speak-to-an-expert" href="#get-in-touch">{post.acf.cta_title}</a>)
												
											} else {
												return(<a data-animation="animated zoomInUp"  class="btn btn-theme effect btn-md center-button-110 " href={post.acf.cta_url}>{post.acf.cta_title}</a>)
											}
										})()}
                    </div>
                </div>
            </div>

           
        </section>
					</div>
				
				</Layout>
			</div>
		);
	}
}
export default Admissions; 

export const AdmissionsPage = graphql `
    {
        allWordpressPage(filter: {wordpress_id: {in: [7013, 2744, 5383, 4933, 2667, 6098, 4925]}}) {
            nodes {
              title
              wordpress_id
              featured_media {
                source_url
              }
              acf {
                 
                meta_title
meta_description
focus_keyphrase
canonical_url
dynamic_schema
               banner_detail
                application_process_title
                application_process {
								title
                  detail
                  
                }
                scholarships_content
                scholarships_text_before_number
                scholarships_number
                scholarships_text_after_number
                scholarships_button
                scholarships_button_link
                scholarships_small_text
                scholarships_image {
                  source_url
                }

                key_dates_title
     
                select_universities_dropdown
                cta_title
                cta_url
               

              }
        }

        
    }
    allWordpressWpUniversities {
        edges {
          node {
            title
            wordpress_id
            acf {
              country
              intake_dates_university {
                intake_date_university
                intake_detail_university 
              }
              intake_dates_university_progression {
                intake_date_university
                intake_detail_university 
              }
            }
          }
        }
    }
    
}
`
/*
    allWordpressPage(filter: { wordpress_id: { in: 5383 } }) {
        edges {
            node {
                wordpress_id
                title
               slug
                acf {
                    inkate_dates
                    select_universities
                }
            }
        }
    }
    */